module.exports = {
  translation: {
    "home": "Home",
    "pl0": 'Single',
    "pl1": 'Aggregate foursome', 
    "pl2": 'Foursome', 
    "pl3": 'Foursome', 
    "pl4": 'Chapman foursome', 
    "pl5": 'Ryesome foursome', 
    "pl6": 'Fourball', 
    "pl7": 'Fourball bestball', 
    "pl8": 'Scramble', 
    "pl9": 'Scramble 2s', 
    "pl10": 'Texas scramble', 
    "pl11": 'Florida scramble', 
    "pl12": 'RPR',
    "sm0": 'Strokeplay',
    "sm1": 'Stableford',
    "sm2": 'Against par',
    "sm3": 'Matchplay',
    "sm4": 'Strokeplay/Stableford',
    "sm5": 'Mixed',
    "sm6": '',
    "sm7": 'Free',
    "sm8": 'Strokeplay max score',
    "startlist": "Draw",
    "round": "Round",
    "time": "TIME",
    "flight": "GROUP",
    "today": "TODAY",
    "hole": "HOLE",

    // Errors
    "dfdae65b": "No user found with the given email address",
    "71b72952": "Wrong password",
    "date": "Date",
    "comment": "Comment",
    "hcpi": "HCPI",
    "scoreDifferential": "SD",
    "adjustedGrossScore": "GBE",
    "deleteScoringRecordItemHeader": "Delete Scoring Record",
    "deleteScoringRecordItemMessage": "Really delete entry with ID?",
    "cancel": "Cancel",
    "ok": "Ok",
    "tournamentName": "Tournament name",
    "round": "Round",
    "tournamentClub": "Tournament club",
    "par": "PAR",
    "courseRating": "Course Rating",
    "slopeRating": "Slope Rating",
    "playForm": "Playform",
    "holes": "Holes",
    "adjustedGrossScore": "GBE",
    "snpv": "SNPV",
    "playingHcpi": "Playing HCPI",
    "adjustment": "Adjustment",
    "adjustmentScoreDifferential": "Adjustment SD",
    "comment": "Comment",
    "ignoreExceptionalScoreAdjustment": "Ignore Exceptional Score",
    "resultStatus": "Result status",
    "country": "Country",
    "tournamentId": "Tournament ID",
    "courseName": "Course name",
    "courseId": "Course ID",
    "pcc": "PCC",
    "scoreDifferentialPostPcc": "SD past PCC",
    "id": "ID",
    "pleaseLogin" : 'Please login',
    "playerName" : 'Player name',
    "playerId" : 'Player ID',
    "clubName" : 'Club name',
    "clubId" : 'Club ID',
    "currentHCPI" : 'Aktuelles HCPI',
    "calculatesHCPI" : 'Kalkuliertes HCPI',
    "scoreDifferentialWhsApi": "SD WHS",
    "editEntries": "Edit entries",
    "resetPassword": "Set new password",
    "invalidToken": "Invalid token",
    "passwordToShort": "Password to short (min. 8 characters)",
    "passwordMissmatch": "Passwords do not match",
    "passwordSaved": "Password saved",
    "passwordSavedError": "Error saving password",
    "resetSent": "The password reset link was sent to your email",
    "resetSentError": "Error sending password reset link",
    "provideEmail": "Please provide your email address",
  }
}