module.exports = {
  translation: {
    "home": "Start",
    "pl0": 'Einzel',
    "pl1": 'Aggregat Vierer', 
    "pl2": 'Vierer', 
    "pl3": 'Vierer Auswahldrive', 
    "pl4": 'Chapman Vierer', 
    "pl5": 'Ryesome Vierer', 
    "pl6": 'Vierball', 
    "pl7": 'Vierball Bestball', 
    "pl8": 'Scramble', 
    "pl9": 'Scramble 2er', 
    "pl10": 'Scramble Texas', 
    "pl11": 'Scramble Florida', 
    "pl12": 'RPR',
    "sm0": 'Zählspiel',
    "sm1": 'Stableford',
    "sm2": 'Gegen Par',
    "sm3": 'Lochspiel',
    "sm4": 'Zählspiel/Stableford',
    "sm5": 'Gemischt',
    "sm6": '',
    "sm7": 'Frei',
    "sm8": 'Zählspiel Maxscore',
    "startlist": "Startliste",
    "round": "Runde",
    "time": "ZEIT",
    "flight": "SPIELGRUPPE",
    "today": "HEUTE",
    "hole": "LOCH",

    // Errors
    "dfdae65b": "Kein Benutzer mit der Email-Adresse gefunden",
    "71b72952": "Kennwort falsch",
    "date": "Datum",
    "comment": "Kommentar",
    "hcpi": "HCPI",
    "scoreDifferential": "SD",
    "adjustedGrossScore": "GBE",
    "deleteScoringRecordItemHeader": "Scoring Record Löschen",
    "deleteScoringRecordItemMessage": "Sind Sie sicher, dass Sie den Scoring Record Eintrag mit der folgenden ID löschen möchten?",
    "cancel": "Abbrechen",
    "ok": "Ok",
    "tournamentName": "Turnier Name",
    "round": "Runde",
    "tournamentClub": "Turnier Club",
    "par": "PAR",
    "courseRating": "Course Rating",
    "slopeRating": "Slope Rating",
    "playForm": "Spielform",
    "holes": "Löcher",
    "adjustedGrossScore": "GBE",
    "snpv": "SNPV",
    "playingHcpi": "Playing HCPI",
    "adjustment": "Anpassung",
    "adjustmentScoreDifferential": "Anpassung SD",
    "comment": "Kommentar",
    "ignoreExceptionalScoreAdjustment": "Exceptional Score Ignorieren",
    "resultStatus": "Ergebnis Status",
    "country": "Land",
    "tournamentId": "Turnier ID",
    "courseName": "Kurs Name",
    "courseId": "Kurs ID",
    "pcc": "PCC",
    "scoreDifferentialPostPcc": "scoreDifferentialPostPcc",
    "id": "ID",
    "pleaseLogin" : 'Bitte einloggen',
    "playerName" : 'Spieler Name',
    "playerId" : 'Spieler ID',
    "clubName" : 'Clubname',
    "clubId" : 'Club ID',
    "currentHCPI" : 'Aktuelles HCPI',
    "calculatesHCPI" : 'Kalkuliertes HCPI',
    "scoreDifferentialWhsApi": "SD WHS",
    "editEntries": "Felder bearbeiten",
    "resetPassword": "Neues Kennwort vergeben",
    "invalidToken": "Token ist inkorrekt",
    "passwordToShort": "Kennwort zu kurz (min. 8 Zeichen)",
    "passwordMissmatch": "Kennwörter stimmen nicht überein",
    "passwordSaved": "Kennwort wurde gespeichert",
    "passwordSavedError": "Fehler beim Speichern des Kennwort",
    "resetSent": "Der Link für den Kennwort Reset wurde Ihnen per Mail zugesachickt",
    "resetSentError": "Es ist ein Fehler aufgetreten den Kennwort Reset anzufordern",
    "provideEmail": "Bitte geben Sie Ihre Email Adresse an",
  }
}