import i18next from 'i18next';
import Storage from "./storage";

const en = require('../i18nData/en');
const de = require('../i18nData/de');

i18next.init({
  lng: 'de',
  debug: false,
  resources: {
    en,
    de,
  }
})

Storage.getItem('language').then(res => {
  const lang = res ? res : 'de'
  i18next.changeLanguage(lang)
})

const translate =  (key) => {
    //const lang = await Storage.getItem('language') || 'en'
   return i18next.t(key)
}

export default translate