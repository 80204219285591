<template>
  <ion-page>
    <ion-content v-if="findgetIndexPageLoading == false" ref="content">

		<div class="grid-container">
			
			<div class="grid-x grid-padding-x" v-if="(!teamScoresSelected || teamScoresSelected.length == 0) && leagueID != 0 && !getDGLTeamScoreLoading">
				<div class="large-12 cell">
					<ion-item>
						<h4 class="white caption" style="margin-right: 15px;"><b>Keine Daten vorhanden</b></h4>
					</ion-item>
				</div>
			</div>

			<div class="grid-x grid-padding-x" v-if="leagueResult.length <= 0 && teamScoresSelected && teamScoresSelected.length > 0">
				<div class="large-12 cell">
					<div class="primary callout">

          <ion-grid class="ion-no-padding ion-no-margin" v-if="scoringMode == 3 && teamScoresSelected.length > 0 && selectedViewType == 0"> <!-- Matchplay View -->
			<marquee-text  v-if="currentTicker && currentTicker.tickerText != ''" style="color: red;font-size: xx-large;" class="ticker-text caption" :key="currentTicker.id" :duration="18" :repeat="15" :reverse="false">{{ currentTicker.tickerText }}&nbsp; &nbsp; &nbsp; 
              		</marquee-text>
            <ion-row class="ion-justify-content-center ion-no-padding ion-no-margin center-row">
                <ion-grid :fixed="true" class="ion-no-padding ion-no-margin" > 

				  <!--<ion-row v-if="tableCategory == 0" class="ion-justify-content-center ion-no-padding ion-no-margin center-row">
					<h6 class="uppercase dgl_blue ion-no-margin" style="margin-left: 10px"><b>{{ matchDays[0].name }} </b></h6>
				  </ion-row>-->
    
				  <ion-row style="height: 145px;" class="ion-justify-content-center ion-no-padding ion-no-margin center-row">
					<table class="uppercase dgl_blue ion-no-margin">
						<tr>
							<td style="text-align: right; width: 12%; font-weight: bold;"><img :src="require('@/assets/images/' + teamScoresSelected[0]?.logoName)" alt="" width="200" height="200" ></td>
							<td style="text-align: center; ; vertical-align: middle; width: 76%; font-weight: bold;">
								<div class="uppercase dgl_blue ion-no-margin" style="font-size: 28px; margin-bottom: 36px"><b>{{ (matchDays[0].name.split(' ').slice(0, 5).join(' ')).replaceAll('_', ' ') }} </b></div>
								<table>
									<tr>
										<td style="font-size: 22px; text-align: right; width: 38%; font-weight: bold; padding-right: 20px;">{{ teamScoresSelected[0].name }}</td>
										<td style="font-size: 22px; text-align: left; width: 38%; font-weight: bold;  padding-left: 20px;">{{ teamScoresSelected[1].name }}</td>
									</tr>
								</table>
							</td>
							<!--<td style="text-align: right; width: 38%; font-weight: bold; padding-right: 20px;">{{ teamScoresSelected[0].name }}</td>
							<td style="text-align: left; width: 38%; font-weight: bold;  padding-left: 20px;">{{ teamScoresSelected[1].name }}&nbsp;&nbsp;</td>-->
							<td style="text-align: left; width: 12%; font-weight: bold;"><img :src="require('@/assets/images/' + teamScoresSelected[1]?.logoName)" alt="" width="200" height="200"></td>
						</tr>
					</table>
				  </ion-row>

				  <ion-row class="ion-justify-content-center ion-no-padding ion-no-margin center-row">
					<div class="ScoringModule-tracker">
					<div class="ScoreTracker">
						<div class="ScoreTracker-bar">
							<div class="ScoreTrackerTeam" data-team="team1" data-guest="false" data-projected="true" :style="'width: ' + getPercent(0, true)"></div>
							<div class="ScoreTrackerTeam" data-team="team1" data-guest="false" data-projected="false" :style="'width: ' + getPercent(0, false)"></div>
							<div class="ScoreTracker-score" data-guest="false" data-color="white">{{ teamScoresSelected[0].club1Total.indexOf('.') > 0 ? Math.floor(teamScoresSelected[0].club1Total) : teamScoresSelected[0].club1Total}}<sup v-if="teamScoresSelected[0].club1Total.indexOf('.') > 0">1/2</sup></div>
							<div class="ScoreTrackerTeam" data-team="team2" data-guest="true" data-projected="true" :style="'width: ' + getPercent(1, true)"></div>
							<div class="ScoreTrackerTeam" data-team="team2" data-guest="true" data-projected="false" :style="'width: ' + getPercent(1, false)"></div>
							<div class="ScoreTracker-score" data-guest="true" data-color="white">{{ teamScoresSelected[0].club2Total.indexOf('.') > 0 ? Math.floor(teamScoresSelected[0].club2Total) : teamScoresSelected[0].club2Total}}<sup v-if="teamScoresSelected[0].club2Total.indexOf('.') > 0">1/2</sup></div><div :class="'ScoreTracker-lines' + gender"></div>
						</div>
					</div>
					<div class="ScoringModule-legend">
						<div class="ScoringModule-legend-item">
							<div class="ScoringModule-symbols">
								<div class="ScoringModule-symbol" data-team="team1"></div>
								<div class="ScoringModule-symbol" data-team="team2"></div>
							</div>
							<div class="ScoringModule-symbol-text">Beendete Matches</div>
						</div>
						<div class="ScoringModule-legend-item" data-projected="true">
							<div class="ScoringModule-symbols">
								<div class="ScoringModule-symbol" data-team="team1"></div>
								<div class="ScoringModule-symbol" data-team="team2"></div>
							</div>
							<div class="ScoringModule-symbol-text">Laufende Matches</div>
						</div>
					</div>
					</div>


				  </ion-row>
                </ion-grid>
            </ion-row>

				<table v-for="round in teamScoresDisplay[0].rounds" :key="round">
					<!-- <tr v-if="round.players.length > 0">
						<th colspan="5" class="padding-top_2 padding-left_0" >
							<span class="category">Runde {{ round.roundIndex  }} -  {{ round.playForm  }}</span>
						</th>
					</tr>-->

				<div v-if="(round.roundIndex == 1 && teamScoresSelected[0].rounds[0].players.length <= 0) || (round.roundIndex == 2 && teamScoresSelected[0].rounds[0].players.length > 0)" class="ScoringModule-panel" data-panel-id="sunday-singles" data-visible="true" data-tab-panel="true"> 
					<div class="ScoringRound">
						<div class="ScoringRound-matches">
							<div class="Match" v-for="(player, i) in round.players" :key="i" data-completed="true" data-type="Singles" data-expanded="false">
								<div class="Match-content" data-content="true">
									<div class="Match-default" tabindex="0" role="button">
										<div class="Match-default-top">
											<div class="MatchTeam" :data-final="player.isCompleted == '(final)' ? true : false" data-host="true" data-team="team1" :data-winner="player.score > player.scoreOpponent ? true : false" :data-tied="player.score == player.scoreOpponent ? true : false">
												<div v-if="player.score > player.scoreOpponent" class="winBkgd" :data-tied="player.score == player.scoreOpponent ? true : false"></div>

												<div style="z-index: 2; margin-top: 15px;">
												<table>
													<tr>
														<td>
															<img style="margin-right: 10px;" :src="player.pictureLink" alt="" width="110" height="110" loading="lazy">
														</td>
														<td v-if="round.playForm == 'Vierer'">
															<img style="margin-right: 10px;" :src="player.pictureLinkPartner" alt="" width="110" height="110" loading="lazy">
														</td>
														<td>
															<div class="MatchPlayer">
																<div class="MatchPlayer-name">
																	<div class="MatchPlayer-firstName" :style="round.roundIndex == 2 ? 'font-size: 1.6rem;' : 'font-size: 1.2rem;'">{{ player.firstname }}</div>
																	<div class="MatchPlayer-lastName" :style="round.roundIndex == 2 ? 'font-size: 1.6rem;' : 'font-size: 1.2rem;'">{{ player.lastname }}</div>
																</div>
																<div v-if="round.playForm == 'Vierer'" class="MatchPlayer-name">
																	<div class="MatchPlayer-firstName" style="font-size: 1.2rem">{{ player.name.split('/')[1].trim().split('  ')[0].trim() }}</div>
																<div class="MatchPlayer-lastName" style="font-size: 1.2rem">{{ player.name.split('/')[1].trim().split('  ')[1].trim() }}</div>
																</div>
															</div>
														</td>
												</tr>
												</table></div>

											</div>
											<div class="Match-topCenter">
												<div class="MatchInfo">
													<div class="MatchInfo-wrapper"><span class="MatchInfo-number">Match {{ i+1 + (playersCount % 2 == 0 || round.playForm == 'Vierer' ? 0 : (round.players.length)) }} -</span><span class="MatchInfo-progress">{{ player.isCompleted == '(final)' && player.holesPlayed > 0 ? 'Final' : player.playedHoles > 0 ? player.holesPlayed : player.isCompleted }}</span>
													</div>
												</div>
												<div class="Match-topScore" data-cta="false">
													<div class="MatchScore" data-score="true">
														<div :class="player.score == player.scoreOpponent ? 'MatchScore-tied' : 'MatchScore-num'" :data-final="player.isCompleted == '(final)' ? true : false" :data-advantage="player.score > player.scoreOpponent ? 'team1' : player.score < player.scoreOpponent ? 'team2' : ''"><span :class="player.scoreLabel == 'geteilt' ? 'MatchScore-large-tie' : 'MatchScore-large'">{{ player.scoreLabel }}</span>
														</div>
													</div>
												</div>
												<div class="Match-cta-mobile"></div>
											</div>
											<div class="MatchTeam" :data-final="player.isCompleted == '(final)' ? true : false" data-host="false" data-team="team2" :data-winner="player.score < player.scoreOpponent ? true : false" :data-tied="player.score == player.scoreOpponent ? true : false">
												<div v-if="player.score < player.scoreOpponent" class="winBkgd" :data-tied="player.score == player.scoreOpponent ? true : false"></div>

												<div style="z-index: 2; margin-top: 15px;">
												<table>
													<tr>
														<td>
															<div class="MatchPlayer">
																<div class="MatchPlayer-name">
																	<div class="MatchPlayer-firstName" :style="round.roundIndex == 2 ? 'font-size: 1.6rem;' : 'font-size: 1.2rem;'">{{ player.opponentFirstname }}</div>
																	<div class="MatchPlayer-lastName" :style="round.roundIndex == 2 ? 'font-size: 1.6rem;' : 'font-size: 1.2rem;'">{{ player.opponentLastname }}</div>
																</div>
																<div v-if="round.playForm == 'Vierer'" class="MatchPlayer-name">
																	<div class="MatchPlayer-firstName" style="font-size: 1.2rem">{{ player.opponentName.split('/')[1].trim().split('  ')[0].trim() }}</div>
																	<div class="MatchPlayer-lastName" style="font-size: 1.2rem;">{{ player.opponentName.split('/')[1].trim().split('  ')[1].trim() }}</div>
																</div>
															</div>
														</td>
														<td>
															<img style="margin-left: 10px;" :src="player.pictureLinkOpponent" alt="" width="110" height="110" loading="lazy">
														</td>
														<td v-if="round.playForm == 'Vierer'">
															<img style="margin-left: 10px;" :src="player.pictureLinkOpponentPartner" alt="Headshot for Jon J." width="110" height="110" loading="lazy">
														</td>
													</tr>
												</table></div>
											</div>
										</div>
										<div class="Match-default-bottom">
											<div class="Match-bottomCenter">
												<div class="MatchHoles">
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[0] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[0] == 1 ? 'team1' : player.scores[0] == 2 ? 'team2' : player.scores[0] == -1 ? 'tie' : ''">1</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[1] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[1] == 1 ? 'team1' : player.scores[1] == 2 ? 'team2' : player.scores[1] == -1 ? 'tie' : ''">2</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[2] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[2] == 1 ? 'team1' : player.scores[2] == 2 ? 'team2' : player.scores[2] == -1 ? 'tie' : ''">3</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[3] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[3] == 1 ? 'team1' : player.scores[3] == 2 ? 'team2' : player.scores[3] == -1 ? 'tie' : ''">4</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[4] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[4] == 1 ? 'team1' : player.scores[4] == 2 ? 'team2' : player.scores[4] == -1 ? 'tie' : ''">5</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[5] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[5] == 1 ? 'team1' : player.scores[5] == 2 ? 'team2' : player.scores[5] == -1 ? 'tie' : ''">6</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[6] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[6] == 1 ? 'team1' : player.scores[6] == 2 ? 'team2' : player.scores[6] == -1 ? 'tie' : ''">7</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[7] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[7] == 1 ? 'team1' : player.scores[7] == 2 ? 'team2' : player.scores[7] == -1 ? 'tie' : ''">8</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[8] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[8] == 1 ? 'team1' : player.scores[8] == 2 ? 'team2' : player.scores[8] == -1 ? 'tie' : ''">9</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[9] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[9] == 1 ? 'team1' : player.scores[9] == 2 ? 'team2' : player.scores[9] == -1 ? 'tie' : ''">10</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[10] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[10] == 1 ? 'team1' : player.scores[10] == 2 ? 'team2' : player.scores[10] == -1 ? 'tie' : ''">11</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[11] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[11] == 1 ? 'team1' : player.scores[11] == 2 ? 'team2' : player.scores[11] == -1 ? 'tie' : ''">12</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[12] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[12] == 1 ? 'team1' : player.scores[12] == 2 ? 'team2' : player.scores[12] == -1 ? 'tie' : ''">13</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[13] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[13] == 1 ? 'team1' : player.scores[13] == 2 ? 'team2' : player.scores[13] == -1 ? 'tie' : ''">14</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[14] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[14] == 1 ? 'team1' : player.scores[14] == 2 ? 'team2' : player.scores[14] == -1 ? 'tie' : ''">15</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[15] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[15] == 1 ? 'team1' : player.scores[15] == 2 ? 'team2' : player.scores[15] == -1 ? 'tie' : ''">16</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[16] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[16] == 1 ? 'team1' : player.scores[16] == 2 ? 'team2' : player.scores[16] == -1 ? 'tie' : ''">17</div>
													<div class="Hole" :data-status="player.holesPlayed == 0 ? '' : player.scores[17] == 0 && player.isCompleted == '(final)' ? 'na' : player.scores[17] == 1 ? 'team1' : player.scores[17] == 2 ? 'team2' : player.scores[17] == -1 ? 'tie' : ''">18</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- <button class="Match-toggler" data-toggler="true"><span class="sr-only">Expand</span></button>-->
							</div>
						</div>
					</div>
				</div>
				</table>
<!--            <ion-row class="ion-justify-content-center ion-no-padding ion-no-margin center-row"  v-for="round in teamScoresSelected[0].rounds" :key="round">
                <ion-grid :fixed="true"> 
                  <ion-row>
                    <div class="roundInfo">Runde {{ round.roundIndex  }} -  {{ round.playForm  }}</div>
                  </ion-row>
                  <ion-row style="font-weight: normal;border-bottom: solid;border-width: 1px;">
                    <ion-col style="text-align: right;" class="centercol">Spieler</ion-col>
                    <ion-col class="centercol">Punkte</ion-col>
                    <ion-col class="centercol">Ergebnis</ion-col>
                    <ion-col class="centercol">Punkte</ion-col>
                    <ion-col style="text-align: left;" class="centercol">Spieler</ion-col>
                  </ion-row>
                   <ion-row style="font-weight: normal;border-bottom: solid;border-width: 1px;" class="cursor" v-for="player in round.players" :key="player" @click="player.showScorecard = !player.showScorecard">
                    <ion-col style="text-align: right;" :class="player.score > player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.name }}</ion-col>
                    <ion-col :class="player.score > player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.score }}</ion-col>
                    <ion-col class="centercol">{{ player.scoreLabel }}<br v-if="player.isCompleted != ''">{{ player.isCompleted }}</ion-col>
                    <ion-col :class="player.score < player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.scoreOpponent }}</ion-col>
                    <ion-col style="text-align: left;" :class="player.score < player.scoreOpponent ? 'centercol blue bold' : 'centercol'"> {{ player.opponentName }}</ion-col>
                    <ion-row v-if="player.showScorecard">
                      <table class="playerScore matchplay">
                        <tbody>
                          <tr>
                            <td>Loch</td>
                            <td v-for="i in 18" :key="i">{{ i }}</td>
                          </tr>
                          <tr>
                            <td>{{ player.name }}</td>
                            <td v-for="label in player.scoresLabel" :key="label">{{ label != 'AS' && label != '--' ? label : '' }}</td>
                          </tr>
                          <tr class="as">
                            <td></td>
                            <td v-for="label in player.scoresLabel" :key="label">{{ label == 'AS' || label == '--' ? label : '' }}</td>
                          </tr>
                          <tr>
                            <td>{{ player.opponentName }}</td>
                            <td v-for="label in player.scoresLabelOpponent" :key="label">{{ label != 'AS' && label != '--' ? label : '' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </ion-row>
                  </ion-row>
                </ion-grid>
            </ion-row>-->
          </ion-grid>



					</div>
				</div>
			</div>
		</div>

				<!-- <ion-grid class="ion-margin">
					<ion-row>
						<ion-col size="auto">
						<ion-searchbar class="ion-no-margin search-input" :search-icon="searchCircle" placeholder="Spieler suchen" v-model="searchText" @input="findPlayer($event.target.value)"></ion-searchbar>
						</ion-col>
						<ion-col size="auto">
						<ion-searchbar class="ion-no-margin search-input" :search-icon="searchCircle" placeholder="Mannschaft suchen" v-model="searchTextTeam" @input="findTeam($event.target.value)"></ion-searchbar>
						</ion-col>
					</ion-row>
				</ion-grid>

				<ion-item v-show="leaguesFound && leaguesFound.length > 0">
				<ion-list>
					<ion-item class="ion-text-wrap" v-for="(league, i) in leaguesFound" :key="i" @click="pushLink(league)" lines="none">
					<ion-label class="table_home cursor ion-text-wrap" @click="pushLink(league)">{{ league.name }}</ion-label>
					</ion-item>
				</ion-list>
				</ion-item>
				<hr>
				<div class="grid">
					<div class="grid_12">
					<div class="col_12 visible">
						<div class="grid_6 pr_0_5" v-for="(structure, i) in structureFound" :key="i" :value="structure">
						<h3 class="h3_headline"><a href="#">{{ structure.header }}</a></h3>
						<span v-for="(league, i) in structure.subLeagues" :key="i" :value="league">
							<h2 v-if="league.league_id && league.league_id != 0" @click="pushLink(league)" class="table_home_headline gray mb_1 cursor"><font-awesome-icon class="icon-1x" icon="flag" />{{ league.name }}</h2>
							<h2 v-else class="table_home_headline gray mb_1"><font-awesome-icon class="icon-1x" icon="flag" />{{ league.name }}</h2>
							<table cellspacing="0" cellpadding="0" class="table_home">
							<template v-for="(subleague, i) in league.subLeagues">
								<tr v-if="i % 2 == 0">
								<td @click="pushLink(subleague)"><a :style="league.name == '1. Bundesliga' ? 'margin-right: -7px !important;': league.name == '2. Bundesliga' ? 'margin-right: -1px !important;': ''" >{{ subleague.name }}</a></td>
								<td @click="pushLink(league.subLeagues[i+1])"><a>{{ league.subLeagues[i+1].name }}</a></td>
								</tr>
							</template>
						</table>
						</span>
						</div>
					</div>
					</div>
				</div>
			-->
		<!--<ion-grid class="my-grid" id="bottom">
			<ion-row class="parent-row">
				<div style="text-align:center;" data-header-ad-wrapper>
					<img v-if="!isPlatform('mobile')" width="524" :src="footerImg">
					<img v-if="isPlatform('mobile')" width="524" :src="footerMobileImg">
				</div>
			</ion-row>
			<ion-row class="parent-row">
				<div style="margin: 10px;color: white;"><a style="margin: 10px;color: white;" href="https://www.clubinone.de" target="_blank">Powered by CLUB IN ONE</a></div>
			</ion-row>
		</ion-grid>-->

   </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonGrid, IonRow, IonCol, loadingController, IonSearchbar, IonItem, IonListHeader, IonLabel, IonList, IonCard,isPlatform, IonButton, IonIcon, modalController } from '@ionic/vue';
import { defineComponent, computed, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import getIndexPageQueryFile from '../graphql/getIndexPage.query.gql'
import getLeaguesQueryFile from '../graphql/getLeagues.query.gql'
import { searchCircle } from 'ionicons/icons';
import VueScriptComponent from 'vue-script-component'
import getDGLTeamScoreQueryFile from '../graphql/getDGLTeamScore.query.gql'
import getDGLNavigationQueryFile from '../graphql/getDGLNavigation.query.gql'
import getDGLSingleScoreQueryFile from '../graphql/getDGLSingleScore.query.gql'
import { refreshOutline, openOutline } from 'ionicons/icons';
import MarqueeText from 'vue-marquee-text-component'
import { useRoute } from 'vue-router';
import Storage from "../plugins/storage.js";

export default defineComponent({
  name: 'Home',
  components: {
    VueScriptComponent, IonPage, IonContent, IonGrid, IonRow, IonCol, IonSearchbar, IonItem, IonListHeader, IonLabel, IonList, IonCard, IonButton, IonIcon,MarqueeText
  },
  data() {
    return {
    }
  },
  setup() {
    const route = useRoute()
    let isLoaded = ref(false)
    let loadingSpinner = ref(null)
    let searchText = ref('')
    let searchTextTeam = ref('')
    let searchType = ref(0) // 0 = player, 1 = team
    let filterSelected = ref(true)
	const tableCategory = ref('0')
	const searchGender = ref('F')
	const searchLiga = ref({ id: 0, name: '1. Bundesliga' })
	const searchStaffel = ref({ id: 0, name: 'Nord' })
	const searchAufstieg = ref('0')
	const tableCategorys = ref(['Spieltage', 'Final Four'])
	const searchClicked = ref(false)
	const leagueID = ref(route.params.lid != undefined ? parseInt(route.params.lid) : 0)
	const parentID = ref(route.params.pid != undefined ? parseInt(route.params.pid) : 0)
	const ltID = ref(route.params.ltid != undefined ? parseInt(route.params.ltid) : 0)
	const lid = ref(route.params.lid != undefined ? parseInt(route.params.lid) : 0)
	const pid = ref(route.params.pid != undefined ? parseInt(route.params.pid) : 0)
	const selectedViewType = ref(0)
    let selectedMatchDay = ref(0)
	const selectedRefresh = ref(0)
    const content = ref();
	const forceReload = ref(false)
	const forceSingleReload = ref(false)
    let selectedSingleRound = ref(0)
	const leagueResult = ref([])
	const teamScoresSelected = ref(undefined)
	const teamScoresDisplay = ref(undefined)
	const searchFound = ref(true)
	const hasParameter = ref(route.params.lid != undefined && route.params.pid != undefined)
	let logoTeam1 = ref(undefined)
	let logoTeam2 = ref(undefined)
    let nextPageIntervalId = ref(0)
	const showDuration = ref(60)
    let nextPlayerIntervalId = ref(0)
	const showDurationPlayer = ref(15)
	const playersOrg = ref([])
	const playersCount = ref(0)

    const { result: findgetIndexPageResult, loading: findgetIndexPageLoading } = useQuery(getIndexPageQueryFile, () => ({ }), () => ({ fetchPolicy: 'no-cache' }) )
    const { result: findLeaguesResult, loading: findLeaguesResultLoading } = useQuery(getLeaguesQueryFile, () => ({ searchText: searchType.value == 0 ? searchText.value : searchTextTeam.value, searchType: searchType.value }), () => ({ fetchPolicy: 'no-cache', enabled: searchClicked }) )


    let leaguesFound = computed(() => findLeaguesResult.value?.getLeagues.leagues ?? [])
    const structureFound = computed(() => findgetIndexPageResult.value?.getIndexPage.leagueStructure ?? [])
    const adLinkTop = computed(() => findgetIndexPageResult.value?.getIndexPage.adLinkTop ?? '')
    const footerImg = computed(() => findgetIndexPageResult.value?.getIndexPage.footerImg ?? '')
    const footerMobileImg = computed(() => findgetIndexPageResult.value?.getIndexPage.footerMobileImg ?? '')

	const { result: getDGLNavigationResult, refetch: getDGLNavigationRefetch } = useQuery(getDGLNavigationQueryFile, () => ({ league_id: leagueID.value, parent_id: parentID.value, league_tournament_id: ltID.value }), () => ({ fetchPolicy: 'no-cache', enabled: leagueID.value > 0 && parentID.value > 0 &&  ltID.value > 0 } ))
    const matchDays = computed(() => getDGLNavigationResult.value?.getDGLNavigation.matchDays ?? '')
    const scoringMode = computed(() => getDGLNavigationResult.value?.getDGLNavigation.scoringMode ?? '')
    const golfdeLink = computed(() => getDGLNavigationResult.value?.getDGLNavigation.golfdeLink ?? '')

    const { result: getDGLTeamScoreResult, refetch: getDGLTeamScoreRefetch, loading: getDGLTeamScoreLoading } = useQuery(getDGLTeamScoreQueryFile, () => ({ matchDayId: parseFloat(route.params.ltid), viewType: selectedViewType.value }), () => ({ fetchPolicy: 'no-cache', enabled: route.params.ltid != undefined } ))
    const teamScores = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.teams ?? [])
    const blitzScores = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.datablitz ?? [])
    const allFinished = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.allFinished ?? true)
    const tickerTeam = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.currentTicker ?? '')
    const teeinfo = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.teeinfo ?? '')
    const leagueinfo = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.leagueinfo ?? '')
    const gender = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.gender ?? '')
    const nextlid = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.lid ?? 0)
    const nextpid = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.pid ?? 0)
    const nextltid = computed(() => getDGLTeamScoreResult.value?.getDGLTeamScore.ltid ?? 0)

	const { result: getDGLSingleScoreResult, refetch: getDGLSingleScoreRefetch, loading: getDGLSingleScoreLoading } = useQuery(getDGLSingleScoreQueryFile, () => ({ matchDayId: parseFloat(selectedMatchDay.value), viewType: selectedViewType.value, roundId: parseInt(selectedSingleRound.value) }), () => ({ fetchPolicy: 'no-cache', pollInterval: 60000*5, enabled: (selectedMatchDay.value != 0 && selectedViewType.value == 1 && tableCategory.value == 0) || forceSingleReload.value } ))
    const singleScoresPlayers = computed(() => getDGLSingleScoreResult.value?.getDGLSingleScore.players ?? [])
    const singleScoresRounds = computed(() => getDGLSingleScoreResult.value?.getDGLSingleScore.rounds ?? [])
    const tickerSingle = computed(() => getDGLSingleScoreResult.value?.getDGLSingleScore.currentTicker ?? '')

	const currentTicker = computed({
        get() {
          if (tickerTeam.value && tickerTeam.value != '') return tickerTeam.value
          if (tickerSingle.value && tickerSingle.value != '') return tickerSingle.value
          return ''
        },
      })

	watch(singleScoresRounds, (newVal, oldVal) => {
  	  forceSingleReload.value = false
      if (newVal && newVal.length > 0 && (selectedSingleRound.value == 0 || !newVal.find(f => f.id == selectedSingleRound.value)))
        selectedSingleRound.value =  newVal[0].id
    })

	watch(searchGender, (newValue, prevValue) => {
		searchLiga.value = { id: 0, name: structureFound.value.find(f => f.header == (searchGender.value == 'F' ? 'Damen' : 'Herren'))?.subLeagues[0].name}
    })

	watch(searchLiga, (newValue, prevValue) => {
		searchStaffel.value = { id: 0, name: structureFound.value.find(f => f.header == (searchGender.value == 'F' ? 'Damen' : 'Herren'))?.subLeagues?.find(f => f.name == searchLiga.value.name).subLeagues[0].name}
    })

    watch(tableCategory, async (newVal, oldVal) => {
		if ((leagueID.value > 0 && parentID.value > 0) || newVal >= 1)
			return
		forceReload.value = true 
		leagueID.value = 0
		parentID.value = 0
		leagueID.value = (await Storage.getItem('leagueID')) != null ? parseInt(await Storage.getItem('leagueID')) : 0;
		parentID.value =  (await Storage.getItem('parentID')) != null ? parseInt(await Storage.getItem('parentID')) : 0;
		tableCategory.value =  (await Storage.getItem('tableCategory')) != null && tableCategorys.value.length - 1 >= (await Storage.getItem('tableCategory')) ? (await Storage.getItem('tableCategory')) : 0;
		searchGender.value =  (await Storage.getItem('searchGender')) != null ? (await Storage.getItem('searchGender')) : 'F';
		searchLiga.value =  (await Storage.getItem('searchLiga')) != null ? JSON.parse(await Storage.getItem('searchLiga')) : { id: 0, name: '1. Bundesliga' };
		searchStaffel.value =  (await Storage.getItem('searchStaffel')) != null ? JSON.parse(await Storage.getItem('searchStaffel')) : { id: 0, name: 'Nord' };
		selectedMatchDay.value = 0
		selectedViewType.value = 0
    }, { immediate: true })

    watch(matchDays, (newVal, oldVal) => {
      selectedMatchDay.value =  newVal.find(f => f.selected == true)?.id
    })

    watch(teamScores, (newVal, oldVal) => {
		clearInterval(nextPageIntervalId.value)
        nextPageIntervalId.value = setInterval(() => {
			if(nextlid.value!=0 && nextpid.value!=0 && nextltid.value!=0) {
				clearInterval(nextPlayerIntervalId.value)
				window.location.href= window.location.href.replace(route.params.lid+'/'+route.params.pid+'/'+route.params.ltid , nextlid.value+'/'+nextpid.value+'/'+nextltid.value)
			}else{
				getDGLTeamScoreRefetch()
			}

        }, showDuration.value * 1000)

/*		if(nextlid.value!=0 && nextpid.value!=0 && nextltid.value!=0){
            window.location.href= window.location.href.replace(route.params.lid+'/'+route.params.pid+'/'+route.params.ltid , nextlid.value+'/'+nextpid.value+'/'+nextltid.value)
		}*/

		if (newVal && newVal.length <= 0) {
			teamScoresSelected.value = newVal
			return
		}
	  if (newVal && newVal.length == 2) {
	 	logoTeam1.value = newVal[0].name == "Hamburger GC" ? require('@/assets/images/logoHamburg.jpeg/') : undefined
		logoTeam2.value = newVal[1].name == "Hubbelrath, GC" ? require('@/assets/images/logoHubbelrath.png/') : undefined
		logoTeam1.value = logoTeam1.value == undefined && newVal[0].name == "Hubbelrath, GC" ? require('@/assets/images/logoHubbelrath.png/') : logoTeam1.value
		logoTeam2.value = logoTeam2.value == undefined && newVal[1].name == "Hamburger GC" ? require('@/assets/images/logoHamburg.jpeg/') : logoTeam2.value
	  }
	  searchFound.value = true
	  playersCount.value = 0
	  teamScoresSelected.value = newVal
	  teamScoresDisplay.value = JSON.parse(JSON.stringify(newVal))
	  if (teamScoresDisplay.value)
	  	teamScoresDisplay.value.forEach(item => {
			item.rounds.filter(f => f.playForm == 'Einzel').forEach(round => {
				playersOrg.value = round.players
				round.players = round.players.slice(0,round.players.length/2)
			})
	  	})

	  clearInterval(nextPlayerIntervalId.value)
        nextPlayerIntervalId.value = setInterval(() => {
			if (teamScoresDisplay.value)
				teamScoresDisplay.value.forEach(item => {
					item.rounds.filter(f => f.playForm == 'Einzel').forEach(round => {
						if (playersCount.value % 2 == 0)
							round.players = playersOrg.value.slice(playersOrg.value.length/2)
						else
							round.players = playersOrg.value.slice(0,playersOrg.value.length/2)
						playersCount.value++
					})
			})
        }, showDurationPlayer.value * 1000)

	  if (scoringMode.value == 3 && teamScoresSelected.value[0].rounds[0].players.length > 0) {
		//teamScoresSelected.value[0].rounds.pop()
	  }
	  forceReload.value = false

	  if (scoringMode.value == 3)
	  	setTimeout(() => { var currentSlot = document.getElementById('ankerMatchplay'); if (currentSlot) currentSlot.scrollIntoView({behavior: 'smooth'}); }, 500)
	  else
 	    setTimeout(() => { var currentSlot = document.getElementById('ankerMatchplay'); if (currentSlot) currentSlot.scrollIntoView({behavior: 'smooth'}); }, 500)

    })

	watch(leaguesFound, (newValue, prevValue) => {
		leagueResult.value = newValue
		if (newValue.length == 0)
			searchFound.value = false
		else
			searchFound.value = true

		searchClicked.value = false
    })


	return {
      structureFound, adLinkTop, findgetIndexPageLoading, footerImg, footerMobileImg, searchText, leaguesFound, searchTextTeam, searchType, searchCircle, filterSelected, tableCategory, searchGender, searchLiga, searchStaffel, tableCategorys, searchClicked, leagueID, parentID, selectedViewType,  teamScores, matchDays, selectedMatchDay, isPlatform, content, refreshOutline, openOutline, getDGLTeamScoreRefetch, isLoaded, teeinfo, searchAufstieg, getDGLTeamScoreLoading, getDGLSingleScoreRefetch, singleScoresRounds, selectedSingleRound, leagueResult, singleScoresPlayers, forceSingleReload, blitzScores, allFinished, scoringMode, golfdeLink, tickerTeam, tickerSingle, currentTicker, hasParameter, gender, leagueinfo, teamScoresSelected, searchFound, lid, pid, logoTeam1, logoTeam2, ltID, teamScoresDisplay, playersCount

    }
  },
  methods: {
	getPercent(teampos, projection) {
		let pointsWithoutScore = 0
		let pointsWithScore = 0
		let totalMatches = 0
		this.teamScoresSelected[0].rounds.forEach(round => {
			totalMatches = round.numMatchesFF
			
			round.players.forEach(player => {
				pointsWithoutScore += (player.holesPlayed == 0 ? 0 : parseFloat(teampos == 0 ? player.score : player.scoreOpponent))
				pointsWithScore += (player.holesPlayed == 0  || player.isCompleted != '(final)' ? 0 : parseFloat(teampos == 0 ? player.score : player.scoreOpponent))
			})
		});

		if (projection)
			return (100 / totalMatches * pointsWithoutScore) + '%'
		else
			return (100 / totalMatches * pointsWithScore) + '%'
	},
	openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    openLink() {
	    window.open(window.location.href + this.leagueID+ '/' + this.parentID, '_blank');
		//this.$router.push('/' + this.leagueID+ '/' + this.parentID)
    },
    refreshScore() {
      this.isLoaded = false
      if (this.selectedViewType == 0)
        this.getDGLTeamScoreRefetch()
      else
        this.getDGLSingleScoreRefetch()
    },
	getSign(score) {
      return score == 0 ? '' : score > 0 ? '+' : ''
    },
	getScoreClass(par, score) {
      if (score - par == 0)
        return 'par'
      if (score - par == -1)
        return 'birdie'
      if (score > 0 && score - par <= -2)
        return 'eagle'
      if (score - par == 1)
        return 'bogey'
      if (score - par == 2)
        return 'doublebogey'
      if (score - par > 2)
        return 'doublebogey'
    },
	getPlusSign(score) {
      if (score == 0)
        return 'Par'
      return score > 0 ? '+' + score : score
    },
    filterStrcuture(name, level) {
		//if (!this.searchClicked)
		//	return false

		if (level == 0 && ((this.tableCategory == '0' && (name == 'FINAL FOUR' || name == 'Aufstieg')) || (this.tableCategory == '1' && (name == 'Damen' || name == 'Herren'))))
			return false
		
		if (level == 0 && this.searchGender == 'F' && name == 'Herren') {
			return false
		}
		if (level == 0 && this.searchGender == 'M' && name == 'Damen') {
			return false
		}
		if (level == 1 && this.searchLiga == '1' && name != '1. Bundesliga') {
			return false
		}
		if (level == 1 && this.searchLiga == '2' && name != '2. Bundesliga') {
			return false
		}
		if (level == 1 && this.searchLiga == '3' && name != 'Regionalliga') {
			return false
		}
		if (level == 1 && this.searchLiga == '4' && name != 'Oberliga') {
			return false
		}
		if (level == 1 && this.searchLiga == '5' && name != 'Landesliga') {
			return false
		}
		if (level == 2 && this.searchStaffel == 'N' && !name.includes('Nord')) {
			return false
		}
		if (level == 2 && this.searchStaffel == 'S' && !name.includes('Süd')) {
			return false
		}
		if (level == 2 && this.searchStaffel == 'W' && !name.includes('West')) {
			return false
		}
		if (level == 2 && this.searchStaffel == 'O' && !name.includes('Mitte')) {
			return false
		}
		return true
    },
    async findPlayer(searchTerm) {
	  this.searchClicked = true
      this.searchType = 0
      this.searchTextTeam = ''
    },
    async findLiga() {
		/*if (this.leagueID > 0) {
			this.refreshScore()
			return
		}*/
		if (this.tableCategory == 0) {
			const league = this.structureFound.find(f => f.header == (this.searchGender == 'F' ? 'Damen' : 'Herren'))
			if (league) {
				const subleague = league.subLeagues.find(f => f.name == this.searchLiga.name)
				if (subleague) {
					const subleague1 = subleague.subLeagues.find(f => f.name.includes(this.searchStaffel.name))
					if (subleague1) {
						if (this.leagueID == subleague1.league_id) {
							this.refreshScore()
						}
						else {
							this.isLoaded = false
							this.leagueID = subleague1.league_id
							this.parentID = subleague1.parent_id
							await Storage.setItem('leagueID', subleague1.league_id);
							await Storage.setItem('parentID', subleague1.parent_id);
							await Storage.setItem('tableCategory', this.tableCategory);
							await Storage.setItem('searchGender', this.searchGender);
							await Storage.setItem('searchLiga', JSON.stringify(this.searchLiga));
							await Storage.setItem('searchStaffel', JSON.stringify(this.searchStaffel));
						}
					}
				}
			}
		}
		else if (this.tableCategory == 1) {
			const league = this.structureFound.find(f => f.header == 'FINAL FOUR')?.subLeagues.find(f => f.name == (this.searchGender == 'F' ? 'Damen' : 'Herren'))
			if (league) {
				this.isLoaded = false
				this.leagueID = league.league_id
				this.parentID = league.parent_id
				await Storage.setItem('leagueID', league.league_id);
				await Storage.setItem('parentID', league.parent_id);
				await Storage.setItem('tableCategory', this.tableCategory);
				await Storage.setItem('searchGender', this.searchGender);
				await Storage.setItem('searchLiga', JSON.stringify(this.searchLiga));
				await Storage.setItem('searchStaffel', JSON.stringify(this.searchStaffel));
			}
		}
		else if (this.tableCategory == 2) {
			const league = this.structureFound.find(f => f.header == 'Aufstieg')?.subLeagues[this.searchAufstieg]
			if (league) {
				this.isLoaded = false
				this.leagueID = league.league_id
				this.parentID = league.parent_id
				await Storage.setItem('leagueID', league.league_id);
				await Storage.setItem('parentID', league.parent_id);
				await Storage.setItem('tableCategory', this.tableCategory);
				await Storage.setItem('searchGender', this.searchGender);
				await Storage.setItem('searchLiga', JSON.stringify(this.searchLiga));
				await Storage.setItem('searchStaffel', JSON.stringify(this.searchStaffel));
			}
		}
    },
    async findTeam(searchTerm) {
	  this.searchClicked = true
      this.searchType = 1
      this.searchText = ''
    },
    async pushLink(league, search = false) {
	  if (this.tableCategory == 0 && !search && league != null)
		return
 	  this.searchText = ''
	  this.searchTextTeam = ''
	  if (league != null) {
		this.leagueID = league.league_id
  	    this.parentID = league.parent_id
 	    await Storage.setItem('leagueID', league.league_id);
		await Storage.setItem('parentID', league.parent_id);
		await Storage.setItem('tableCategory', this.tableCategory);
		await Storage.setItem('searchGender', this.searchGender);
		await Storage.setItem('searchLiga', JSON.stringify(this.searchLiga));
		await Storage.setItem('searchStaffel', JSON.stringify(this.searchStaffel));
  	  }
	  this.leagueResult = []
      //this.$router.push('/score/' + league.league_id + '/' + league.parent_id)
    },
    toggleDisplay(league) {
      if (!league.displayValue)
        league.displayValue = 'none'
      league.displayValue = league.displayValue === 'none' ? 'block' : 'none';
    }
  }
});

if (document.location.search.match(/type=embed/gi)) {
  window.parent.postMessage("resize", "*");
}

</script>

<style lang="scss" scoped>
  @import '../assets/css/foundation.scss'; /* injected */
  @import '../assets/css/app.scss'; /* injected */
  @import '../assets/css/matchplay.scss'; /* injected */
</style>

<style scoped>

.search-input {
	--border-radius: 20px !important;
	--box-shadow: 0px 0px 0px 2px #f1f1f1;
	--icon-color: #004587;
}

.my-grid {
	background-color: #004587;
}
.parent-row {
	background-color: #004587;
    align-items: center !important;
    justify-content: center !important;
 }
.center-row {
    align-items: center !important;
    justify-content: center !important;
 }

.cursor {
    cursor: pointer;
}


.icon-1x {
    font-size: 0.8em;
    margin-right: 5px;
}

.scroll-right {
 height: 50px;	
 overflow: hidden;
 position: relative;
 background: yellow;
 color: orange;
 border: 1px solid orange;
}
.scroll-right p {
 position: absolute;
 width: 100%;
 height: 100%;
 margin: 0;
 line-height: 50px;
 text-align: center;
 /* Starting position */
 -moz-transform:translateX(-100%);
 -webkit-transform:translateX(-100%);	
 transform:translateX(-100%);
 /* Apply animation to this element */	
 -moz-animation: scroll-right 15s linear infinite;
 -webkit-animation: scroll-right 15s linear infinite;
 animation: scroll-right 15s linear infinite;
}
/* Move it (define the animation) */
@-moz-keyframes scroll-right {
 0%   { -moz-transform: translateX(-100%); }
 100% { -moz-transform: translateX(100%); }
}
@-webkit-keyframes scroll-right {
 0%   { -webkit-transform: translateX(-100%); }
 100% { -webkit-transform: translateX(100%); }
}
@keyframes scroll-right {
 0%   { 
 -moz-transform: translateX(-100%); /* Browser bug fix */
 -webkit-transform: translateX(-100%); /* Browser bug fix */
 transform: translateX(-100%); 		
 }
 100% { 
 -moz-transform: translateX(100%); /* Browser bug fix */
 -webkit-transform: translateX(100%); /* Browser bug fix */
 transform: translateX(100%); 
 }
}

</style>