import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'
import TournamentStartList from '../views/TournamentStartList.vue'
import TournamentStartListDMM from '../views/TournamentStartListDMM.vue'
import TournamentLeagueScoring from '../views/TournamentLeagueScoring.vue'
import TournamentLeagueScoringFF from '../views/TournamentLeagueScoringFF.vue'
import TournamentLiveScoringHH from '../views/TournamentLiveScoringHH.vue'
import TournamentResultList from '../views/TournamentResultList.vue'
import TournamentResultListDMM from '../views/TournamentResultListDMM.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tournament/startlist/:tid/:roundIndex',
    name: 'TournamentStartList',
    component: TournamentStartList
  },
  {
    path: '/tournament/startlist/:lid/:gid/:rid',
    name: 'TournamentStartListDMM',
    component: TournamentStartListDMM
  },
  {
    path: '/tournament/leaguescoring/:roundid',
    name: 'TournamentLeagueScoring',
    component: TournamentLeagueScoring
  },
  {
    path: '/tournament/livescoringhh/:roundid',
    name: 'TournamentLiveScoringHH',
    component: TournamentLiveScoringHH
  },
  {
    path: '/tournament/livescoring/:roundid',
    name: 'TournamentLiveScoring',
    component: TournamentLiveScoringHH
  },
  {
    path: '/tournament/resultlist/:roundid/:prizeid',
    name: 'TournamentResultList',
    component: TournamentResultList
  },
  {
    path: '/tournament/resultlist/:lid/:groupid/:roundid',
    name: 'ScoreDMM',
    component: TournamentResultListDMM
  },
  {
    path: '/:lid/:pid/:ltid',
    name: 'TournamentLeagueScoringFF',
    component: TournamentLeagueScoringFF
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
